import React from 'react'

import LinkbackBanner from 'profiles/components/shared/LinkbackBanner'
import usePublicProfile from 'profiles/hooks/usePublicProfile'

export default function ProfileLayout({ children }) {
  const { data: profile } = usePublicProfile()
  const showBranding = !!profile && profile.embed.showBranding

  return (
    <>
      {showBranding && <LinkbackBanner />}
      {children}
    </>
  )
}
