export const publicProfileQuery = `
  query PublicProfile($globalHandle: String!) {
    publicOrganization(globalHandle: $globalHandle) {
      embed
      teamMember(globalHandle: $globalHandle) {
          agentId
          agentStatus {
            online
          }
          orgId
          name
          avatarUrl
          coverPhotoUrl
          smallCoverPhotoUrl: resizedCoverPhotoUrl(widthInPixels: 500, heightInPixels: 400)
          mediumCoverPhotoUrl: resizedCoverPhotoUrl(widthInPixels: 1000, heightInPixels: 800)
          largeCoverPhotoUrl: resizedCoverPhotoUrl(widthInPixels: 1200, heightInPixels: 960)
          jobTitle
          layout
          timezone
          visibility
          bio
          funFact
          latitude
          longitude
          blogUrl
          podcastUrl
          calendarUrl
          phoneNumber
          educationInfo
          quote
          likes
          languages
          facebookHandle
          twitterHandle
          soundCloudHandle
          instagramHandle
          linkedInHandle
          pinterestHandle
          skypeHandle
          location
          handle
          globalHandle
          welcomeMessage,
          awayMessage,
          canAcceptMeetings,
          calendarCta,
          enableCalendarCta,
          calendarOnlyStatus
      }
    }
  }`
