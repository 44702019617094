import React from 'react'

import axios from 'axios'
import { useRegionConfig } from 'core/providers/region'
import { useRouter } from 'next/router'
import { publicProfileQuery } from 'profiles/constants/gql'
import useSWR from 'swr'

interface Profile {
  data: {
    publicOrganization: PublicProfile
  }
}
export interface PublicProfile {
  embed: Embed
  teamMember: TeamMember
}
interface Embed {
  inboxId: number
  refreshRate: number
  layerAppId?: null
  inboxEmailAddress: string
  authClientId: string
  redirectUri: string
  organizationName: string
  organizationAddress?: null
  organizationUrl: string
  organizationTwitterLink?: null
  organizationLinkedinLink?: null
  organizationFacebookLink?: null
  theme: Theme
  dataProtection: DataProtection
  chatEnabled: boolean
  leadChatEnabled: boolean
  enabled: boolean
  widgetStatus: string
  widgetMode?: null
  showBranding: boolean
  campaigns?: CampaignsEntity[] | null
  interactionSlugMap: Record<string, unknown>
  interactionTestingSlugMap: Record<string, unknown>
  gates: Record<string, boolean>
  team?: TeamEntity[] | null
  locale: string
  onCallUsers?: null[] | null
  doNotDisturbUsers?: null[] | null
  atChatLimitUsers?: null[] | null
  rules?: null[] | null
  integrations: Integrations
  teams?: null[] | null
  teamAvailabilities?: null[] | null
  v2WidgetEnabled: boolean
  v2WidgetWhitelistedDomains?: null[] | null
  v2WidgetWhitelistAllSites: boolean
  workspacesWithTargetingConfigs?: null[] | null
  planned: boolean
  id: string
}
interface Theme {
  backgroundColor: string
  foregroundColor: string
  textColor: string
  activeColor: string
  widgetBackgroundColor: string
  logoUrl?: null
  iconUrl?: null
  userListMode: string
  userList?: null[] | null
  welcomeMessage?: null
  awayMessage?: null
  awayMessageSenderId: number
  emailCaptureMessage?: null
  awayImageUrl?: null
  confirmationMessage?: null
  confirmationImageUrl?: null
  audience: string
  timezone: string
  showWelcomeMessage: boolean
  targetId: number
  conditions?: null[] | null
  phoneCollectedWhenAway: boolean
  openHours?: OpenHoursEntity[] | null
  userListIds?: null[] | null
  iconStyle: string
  alignment: string
  fontFamily?: null
  soundNotificationEnabled: boolean
  titleNotificationEnabled: boolean
  unreadBadgeEnabled: boolean
  teamDirectoryHeading?: null
  teamDirectoryDescription?: null
  captureEmail: boolean
  availabilityBasedOnline: boolean
  allowEndUserFileUpload: boolean
  allowEndUserToSendGif: boolean
  allowAgentFileUpload: boolean
  showUnfurl: boolean
  disableNewConversations: boolean
  cookieGeneration?: null
  externalIdBlacklist?: null[] | null
  widgetHalfHeight: boolean
  enableChatIfConversationHistory: boolean
  externalIdWhitelistPattern?: null
  urlWhitelist?: null
  signedIdentityRequiredStatus: string
  cookieDomains?: null
  widgetIconShape?: null
  widgetIconBorderEnabled: boolean
  conversationHistoryEnabled: boolean
  sendTranscriptEnabled: boolean
  widgetIconPosition?: null
}
interface OpenHoursEntity {
  opens: string
  closes: string
  dayOfWeek: string
}
interface DataProtection {
  consentId: string
  default: string
  policies: Record<string, Policy>
  targeting: string
}

interface Policy {
  id: string
  message: string
  giveConsentLabel: string
  declineConsentLabel: string
  consentDeclinedMessage: string
  lastUpdated: number
}
interface CampaignsEntity {
  id: number
  inboxId: number
  type: string
  audience: string
  userListIds?: null[] | null
  createdAt: number
  conditions?: (ConditionsEntityEntity[] | null)[] | null
  attributes: Attributes
  controlResponsibility: string
}
interface ConditionsEntityEntity {
  field: string
  operator: string
  value: boolean
  providerName?: null
}
interface Attributes {
  message: string
  cta: Cta
  senderId: number
  title: string
  delivery: string
  messageType: string
  messageDeliveryChannel: string
  displayType: string
  playbookId: number
  triggers?: TriggersEntity[] | null
  automaticSender: boolean
  isRepeatable: boolean
  outbound: boolean
  testSequence: boolean
  unenrollOnReply: boolean
  unenrollOnReplyByDomain: boolean
  excludedUserListIds?: null[] | null
  roundRobinSenderList?: null[] | null
  smartSending: boolean
  weekdaysOnly: boolean
  threadedWithPrevious: boolean
  campaignReenrollmentType: string
  accountListIds?: null[] | null
  excludedAccountListIds?: null[] | null
  orderInPlaybook?: null
  allowTeamAvailability: boolean
  isDriftVideoCampaign: boolean
  updatedAt: number
  isExperiment: boolean
  hasFallback: boolean
}
interface Cta {
  copy: string
  CtaType: string
}
interface TriggersEntity {
  goalId: string
  playbookId?: null
  conditions?: (ConditionsEntityEntity1[] | null)[] | null
}
interface ConditionsEntityEntity1 {
  action: string
  operator: string
  value: string
}

interface TeamEntity {
  id: number
  name: string
  avatarUrl?: null
  status: string
  bot: boolean
  title?: null
  publicProfileUrl?: string | null
}
interface Integrations {
  googleAnalyticsSettings: GoogleAnalyticsSettings
  connections: Record<string, boolean>
  integrationLookUp: Record<string, unknown>
}
interface GoogleAnalyticsSettings {
  enabled: boolean
  trackingName?: null
  individualEventsEnabled: boolean
  enabledEventMappings: Record<string, unknown>
}
interface TeamMember {
  agentId: number
  agentStatus: AgentStatus
  orgId: number
  name: string
  avatarUrl: string | null
  coverPhotoUrl: string | null
  smallCoverPhotoUrl: string | null
  mediumCoverPhotoUrl: string | null
  largeCoverPhotoUrl: string | null
  jobTitle: string | null
  layout: string | null
  timezone: string | null
  visibility: string
  bio: string | null
  funFact: string | null
  latitude: number
  longitude: number
  blogUrl: string | null
  podcastUrl: string | null
  calendarUrl: string | null
  phoneNumber: string | null
  educationInfo: string | null
  quote: string | null
  likes: string | null
  languages: string | null
  facebookHandle: string | null
  twitterHandle: string | null
  soundCloudHandle: string | null
  instagramHandle: string | null
  linkedInHandle: string | null
  pinterestHandle: string | null
  skypeHandle: string | null
  location: string | null
  handle: string
  globalHandle: string
  welcomeMessage: string | null
  awayMessage: string | null
  canAcceptMeetings: boolean
  calendarCta: string | null
  enableCalendarCta: boolean | null
  calendarOnlyStatus: boolean
}
interface AgentStatus {
  online: boolean
}

const usePublicProfile = () => {
  const { query } = useRouter()
  const { CUSTOMER_API } = useRegionConfig()

  const fetcher = React.useCallback(
    (globalHandle: string) =>
      axios
        .post<Profile>(`${CUSTOMER_API}/graphql`, {
          query: publicProfileQuery,
          variables: { globalHandle },
        })
        .then((res) => res.data.data.publicOrganization),
    [CUSTOMER_API]
  )

  return useSWR(query.globalHandle || null, fetcher)
}

export default usePublicProfile
