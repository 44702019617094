import { useRouter } from 'next/router'

const useMeetingSlug = () => {
  const { query } = useRouter()

  let slug = 'meeting'
  if (Array.isArray(query.meetingSlug) && query.meetingSlug.length) {
    slug = query.meetingSlug[0]
  } else if (Array.isArray(query.customSlug) && query.customSlug.length) {
    slug = query.customSlug[0]
  } else if (query.meetingId) {
    slug = query.meetingId as string
  }
  return slug
}

export default useMeetingSlug
