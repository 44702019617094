import styles from 'profiles/styles/components/CircleLoader.module.css'

const CircleLoader = () => (
  <div className={styles.CircleLoader}>
    <svg className={styles.CircleLoaderSpinner} viewBox="25 25 50 50">
      <circle
        className={styles.CircleLoaderCircle}
        cx="50"
        cy="50"
        r="20"
        fill="none"
      />
    </svg>
  </div>
)

export default CircleLoader
