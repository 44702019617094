import { BriefcaseIcon, LocationIcon } from 'core/constants/icons'
import { ensureUri } from 'core/utils/urls'
import usePublicProfile from 'profiles/hooks/usePublicProfile'
import { useTranslationWithFallback } from 'profiles/utils/translations'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const MaybeLink = ({ label, href, target = '_blank', ...rest }) => {
  if (href) {
    return (
      <a
        {...rest}
        href={ensureUri(href)}
        target={target}
        rel="noopener noreferrer"
      >
        {label}
      </a>
    )
  }
  return <span>{label}</span>
}

const Location = () => {
  const { data: profile } = usePublicProfile()
  const { teamMember, embed } = profile

  const translatedAt = useTranslationWithFallback({
    key: 'meeting.employedAt',
    fallback: 'at',
  })

  return (
    <>
      {teamMember.jobTitle && (
        <div className={styles.AboutSection}>
          <BriefcaseIcon marginRight={'16px'} width={17} height={17} />
          <span>
            {teamMember.jobTitle}{' '}
            {embed.organizationName ? ` ${translatedAt} ` : null}{' '}
            {
              <MaybeLink
                href={embed.organizationUrl}
                label={embed.organizationName}
              />
            }
          </span>
        </div>
      )}
      {teamMember.location && (
        <div className={styles.AboutSection}>
          <LocationIcon marginRight={'16px'} width={17} height={17} />
          <span>{teamMember.location}</span>
        </div>
      )}
    </>
  )
}

export default Location
