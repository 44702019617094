const ALLOWED_PROTOCOLS = ['http', 'https']

const protocolIsAllowed = (protocol: string) =>
  ALLOWED_PROTOCOLS.includes(protocol)

export function ensureUri(uri: string) {
  return [uri]
    .filter((uri) => (/^javascript:/.test(uri) ? null : uri))
    .filter(Boolean)
    .map((uri) => uri.split(':'))
    .map(([protocol, host]) =>
      !host ? ['http', `//${protocol}`] : [protocol, host]
    )
    .filter(([protocol]) => protocolIsAllowed(protocol))
    .map(([protocol, host]) => `${protocol}:${host}`)
    .reduce((_, b) => b, 'about:blank')
}

export function encodeData(data: Record<string, string | number | boolean>) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

export function encodeQueryData(
  data: Record<string, string | number | boolean>
) {
  const ret = []
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach((v) =>
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
      )
    } else if (value) {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }
  return ret.join('&')
}

export function decodeToAscii(recipientsBase64: string[]) {
  // check if in ascii format (before changes introduced to extension)
  for (let i = 0; i < recipientsBase64.length; i++) {
    if (recipientsBase64[i].includes('@')) {
      return recipientsBase64
    }
  }
  return recipientsBase64.map((r64) => atob(r64))
}
