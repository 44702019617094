import { DriftLogo } from 'core/constants/icons'
import { useTranslationWithFallback } from 'profiles/utils/translations'

import styles from 'profiles/styles/components/LinkbackBanner.module.css'

const LinkbackBanner = () => {
  const url = `https://www.drift.com/?utm_source=drift-meetings&utm_medium=profile-page&utm_campaign=meetings-referral`

  return (
    <div className={styles.BannerContainer}>
      <div className={styles.BannerContainerLeft}>
        <DriftLogo />
        <div className={styles.BannerText}>
          {useTranslationWithFallback({
            key: 'meeting.createFreeDriftAccountCta',
            fallback:
              'Like what you see? Create your FREE Drift account to start having conversations',
          })}
        </div>
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <button className={styles.BannerButton}>
            {useTranslationWithFallback({
              key: 'meeting.getDrift',
              fallback: 'Get Drift',
            })}
          </button>
        </a>
      </div>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <div className={styles.BannerByDrift}>
          {useTranslationWithFallback({
            key: 'meeting.profileBolt',
            fallback: 'Profile\u26a1\ufe0f by Drift',
          })}
        </div>
      </a>
    </div>
  )
}

export default LinkbackBanner
